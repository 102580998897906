<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-6 mb-8 black--text">
        Czy na pewno chcesz
        <template v-if="isScheduleBlocked">
          odblokować
        </template>
        <template v-else>
          zablokować
        </template>
        <b> harmonogram {{ schedule.id }}</b>?
      </div>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="updateValue()"
        @click="updateValue()"
      >
        <template v-if="isScheduleBlocked">
          Odblokuj
        </template>
        <template v-else>
          Zablokuj
        </template>
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
      isProcessing: state => state.orderSchedules.isProcessing
    }),
    schedule () {
      return this.dialog.item
    },
    isScheduleBlocked () {
      return this.dialog.data.action === 'unblockOrderSchedule'
    }
  },
  methods: {
    ...mapActions({
      setScheduleBlock: 'orderSchedule/setScheduleBlock',
      setScheduleUnblock: 'orderSchedule/setScheduleUnblock'
    }),
    updateValue () {
      const { id, active } = this.schedule
      const params = {
        id,
        params: {
          active: !active
        }
      }
      if (this.isScheduleBlocked) {
        this.setScheduleUnblock(params)
      } else {
        this.setScheduleBlock(params)
      }
    }
  }
}
</script>
